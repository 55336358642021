var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var size = {
    width: 0,
    height: 0,
    yProgression: function (scrollY) { return scrollY / this.height; }
};
var easeInOutCubic = function (t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1; };
function resize() {
    size.width = window.innerWidth;
    size.height = window.innerHeight;
}
resize();
window.addEventListener('resize', resize);
var home = {
    calendarList: document.querySelector('#js-calendarList'),
    lockerTimeout: null
};
document.addEventListener('scroll', function (e) {
    var scrollY = window.scrollY;
    nav.scrollEvent(scrollY);
    var progress = size.yProgression(scrollY);
    hero.scrollEvent(progress);
    missions.scrollEvent(progress);
    missionsCanvas.scrollEvent(progress);
    // Bloquer le scroll de la partie actualiités
    clearTimeout(home.lockerTimeout);
    home.calendarList.classList.add('locked');
    home.lockerTimeout = setTimeout(function (_) {
        home.calendarList.classList.remove('locked');
    }, 100);
    // Lancer un timeout pour le réactiver après x secondes
});
var nav = {
    lastScrollYPos: 0,
    menuButton: document.querySelector('#js-menuIcon'),
    nav: document.querySelector('#js-nav'),
    sections: document.querySelectorAll('#js-homeContent section'),
    sectionHeader: document.querySelector('#js-header'),
    sectionNavLinks: document.querySelectorAll('.js-navLink'),
    setup: function () {
        var _this = this;
        this.sectionHeader.addEventListener('mouseenter', function (_) {
            _this.sectionHeader.classList.remove('hidden');
        });
        this.menuButton.addEventListener('click', function (e) {
            e.preventDefault();
            _this.nav.classList.toggle('open');
            _this.menuButton.classList.toggle('open');
        });
        this.sectionNavLinks.forEach(function (link) {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                _this.nav.classList.remove('open');
                _this.menuButton.classList.remove('open');
                var href = link.getAttribute('href');
                var destY = href == '#hero' ? 0 : document.querySelector(href).offsetTop + size.height;
                var initialY = window.scrollY;
                var offsetY = destY - initialY;
                var frames = Math.abs(offsetY) * 0.02;
                var _loop_1 = function (i) {
                    var y = initialY + (offsetY * easeInOutCubic(i / frames));
                    setTimeout(function (_) {
                        window.scrollTo({ top: y });
                    }, i * 1000 / 60);
                };
                for (var i = 1; i <= frames; i++) {
                    _loop_1(i);
                }
            });
        });
    },
    scrollEvent: function (scrollY) {
        console.log(scrollY);
        if (scrollY > 0 && scrollY > this.lastScrollYPos) {
            this.sectionHeader.classList.add('hidden');
        }
        else {
            this.sectionHeader.classList.remove('hidden');
        }
        this.lastScrollYPos = scrollY;
    }
};
nav.setup();
var hero = {
    pictureHidder: document.querySelector('#js-pictureHidder'),
    background: document.querySelector('#js-hero-background'),
    scrollEvent: function (progress) {
        this.background.style.transform = "translateY(" + window.scrollY * -0.4 + "px)";
        this.pictureHidder.style.transform = "scaleY(" + progress * 1.4 + ")";
    }
};
var missions = {
    singleMissions: document.querySelectorAll('.js-singleMission'),
    missionParagraphHeights: [],
    scrollEvent: function (progress) {
        var focusedMissionKey = Math.round(progress - 0.25) - 1;
        this.singleMissions.forEach(function (mission, key) {
            if (key == focusedMissionKey) {
                mission.classList.add('focused');
                var openingStep = (progress - 1.1) - focusedMissionKey;
                if (openingStep >= 0) {
                    mission.classList.add('opened');
                }
                else {
                    mission.classList.remove('opened');
                }
            }
            else {
                mission.classList.remove('focused');
            }
        });
    },
    resizeEvent: function () {
        this.singleMissions.forEach(function (mission) {
            var p = mission.querySelector('p');
            var pHeight = 0;
            if (p) {
                var style = getComputedStyle(p, null);
                var margin = parseFloat(style.marginTop) + parseFloat(style.marginBottom);
                pHeight = p.offsetHeight + margin;
            }
            var heading1 = mission.querySelector('h2:first-of-type');
            var heading2 = mission.querySelector('h2:last-of-type');
            heading1.style.transform = "translateY(" + pHeight / 2 + "px)";
            heading2.style.transform = "translateY(-" + pHeight / 2 + "px)";
        });
    }
};
window.addEventListener('resize', function (_) { missions.resizeEvent(); });
missions.resizeEvent();
var missionsCanvas = {
    elems: {
        main: document.querySelector('#js-missionsCanvas'),
        funShapes: document.querySelectorAll('.js-funShape'),
        alumnisMacaron: document.querySelector('#js-alumnisMacaron'),
        alumnisMacaronBackground: document.querySelector('#js-alumnisMacaronBackground'),
        alumnisMacaronContent: document.querySelector('#js-alumnisMacaronContent'),
        alumnisMacaronShape: document.querySelector('#js-alumnisMacaronShape'),
        dialogueHalfBubble: document.querySelector('#js-dialogueHalfBubble'),
        dialogueBubble: document.querySelector('#js-dialogueBubble'),
        alumniBrown: document.querySelector('#js-alumniBrownCircle'),
        alumniBrownBackground: document.querySelector('#js-alumniBrownCircleBackground'),
        gridMainBubble: document.querySelector('#js-gridMainBubble'),
        gridBubbles: document.querySelectorAll('.js-gridBubble')
    },
    destProgression: 0,
    progression: 0,
    update: function () {
        var _this = this;
        requestAnimationFrame(this.update.bind(this));
        this.progression = this.progression + (this.destProgression - this.progression) * 0.1;
        var step1Progression = Math.min(this.progression / 0.8, 1);
        var step1TransitionProgression = Math.max(0, Math.min(1, (this.progression - 0.8) / 0.4));
        var step2Progression = Math.max(0, Math.min(1, (this.progression - 1.2) / 0.8));
        var step2TransitionProgression = Math.max(0, Math.min(1, (this.progression - 1.8) / 0.4));
        var step3Progression = Math.max(0, Math.min(1, (this.progression - 2.2) / 0.8));
        var step3TransitionProgression = Math.max(0, (this.progression - 3.0) / 0.4);
        // General
        this.elems.main.style.opacity = this.destProgression < 1 ? this.destProgression * 2 : (7.5 - 2 * this.destProgression);
        this.elems.funShapes.forEach(function (funShape) {
            var _a, _b;
            var speedX = (_a = parseFloat(funShape.getAttribute('data-speedX'))) !== null && _a !== void 0 ? _a : 1;
            var speedY = (_b = parseFloat(funShape.getAttribute('data-speedY'))) !== null && _b !== void 0 ? _b : 1;
            funShape.style.transform = "translate(" + 10 * _this.progression * speedX + "vh, " + 10 * _this.progression * speedY + "vh)";
            funShape.style.opacity = "" + (1 - step2Progression);
        });
        // Step 1 progression
        this.elems.alumnisMacaron.style.transform = "translateY(-" + 75 * (1 - step1Progression) + "vh) rotate(" + (-45 + step1Progression * 45 + step1TransitionProgression * 90) + "deg)";
        this.elems.alumniBrownBackground.style.transform = "translateY(-" + 66 * step1Progression + "%)";
        this.elems.alumniBrown.style.transform = "translateY(" + (-100 + 300 * (2 - step1Progression - step1TransitionProgression)) + "%)";
        // Step 1 transition
        this.elems.alumnisMacaronBackground.style.transform = "translateY(-" + 66 * Math.min(1, step1Progression - 0.5 + step1TransitionProgression) + "%)";
        this.elems.alumnisMacaronContent.style.opacity = 1 - step1TransitionProgression;
        this.elems.alumnisMacaronShape.style.maskSize = 100 + 12 * step1TransitionProgression + "%";
        this.elems.alumnisMacaronShape.style.webkitMaskSize = 100 + 12 * step1TransitionProgression + "%";
        this.elems.alumniBrown.style.opacity = 1 - step1TransitionProgression;
        this.elems.dialogueHalfBubble.style.visibility = step1TransitionProgression == 1 && step2TransitionProgression < 1 ? 'visible' : 'hidden';
        // Step 2 progression
        this.elems.dialogueBubble.style.transform = "translateY(" + 75 * (1 - step2Progression) + "vh)";
        // Step 2 transition
        this.elems.dialogueHalfBubble.style.transform = "rotate(" + step2TransitionProgression * 45 + "deg)";
        this.elems.alumnisMacaron.style.visibility = step2TransitionProgression < 1 ? 'visible' : 'hidden';
        this.elems.dialogueBubble.style.visibility = step2TransitionProgression < 1 ? 'visible' : 'hidden';
        // Step 3 progression
        var reversedStep3Progression = 1 - step3Progression;
        this.elems.gridMainBubble.style.visibility = step2TransitionProgression == 1 ? 'visible' : 'hidden';
        if (size.width > 700) {
            this.elems.gridMainBubble.style.transform = "translate(\n                calc(" + 32 * reversedStep3Progression + "vh - " + 25 * reversedStep3Progression + "vw),\n                calc(" + 32 * reversedStep3Progression + "vh - " + (50 * reversedStep3Progression + step3TransitionProgression * 30) + "vh)\n                )\n                scale(" + (1 * reversedStep3Progression + 1) + ")\n                rotate(" + (45 + 45 * step3Progression) + "deg)";
        }
        else {
            this.elems.gridMainBubble.style.transform = "translate(\n                calc(" + 32 * reversedStep3Progression + "vh - " + 50 * reversedStep3Progression + "vw),\n                calc(" + 32 * reversedStep3Progression + "vh - " + (20 * reversedStep3Progression + step3TransitionProgression * 30) + "vh - " + 20 * reversedStep3Progression + "px)\n                )\n                scale(" + (1 * reversedStep3Progression + 1) + ")\n                rotate(" + (45 + 45 * step3Progression) + "deg)";
        }
        this.elems.gridBubbles.forEach(function (bubble) {
            var _a;
            var pos = (_a = parseFloat(bubble.getAttribute('data-pos'))) !== null && _a !== void 0 ? _a : 1;
            bubble.style.opacity = "" + (-0.5 + step2TransitionProgression + step3Progression);
            bubble.style.transform = "translateY(-" + ((10 * (1 - step2TransitionProgression) * pos) + (20 * reversedStep3Progression * pos) + (step3TransitionProgression * pos * 30)) + "vh)";
        });
    },
    scrollEvent: function (_pageProgress) {
        this.destProgression = _pageProgress - 0.5;
    }
};
missionsCanvas.update();
var ambassadors = {
    index: 0,
    scrollBox: document.querySelector('#js-ambassadorsScrollBox'),
    buttons: document.querySelectorAll('.js-areaButton'),
    ambassadorsAreas: document.querySelectorAll('.js-ambassadorArea'),
    ambassadorsAreaLimits: [],
    setup: function () {
        var _this = this;
        this.buttons.forEach(function (button) {
            button.addEventListener('click', function (e) {
                _this.buttonClick(e, button);
            });
        });
        this.scrollBox.addEventListener('scroll', function (_) { _this.scrollEvent(); });
        this.resize();
        window.addEventListener('resize', function (_) { _this.resize(); });
    },
    resize: function () {
        this.ambassadorsAreaLimits = __spreadArray([], this.ambassadorsAreas).map(function (area) { return (area.offsetLeft - (size.width / 2)); });
        this.scrollEvent();
    },
    buttonClick: function (e, button) {
        var _this = this;
        e.preventDefault();
        if (button.classList.contains('focus')) {
            return;
        }
        var targetId = '#js-ambassadorsArea-' + button.getAttribute('data-target');
        var target = document.querySelector(targetId);
        var initialX = this.scrollBox.scrollLeft;
        var desiredX = target.offsetLeft - 80;
        var offsetX = desiredX - initialX;
        var frames = Math.abs(offsetX) * 0.025;
        var _loop_2 = function (i) {
            var x = initialX + (offsetX * easeInOutCubic(i / frames));
            setTimeout(function (_) {
                _this.scrollBox.scrollTo({ left: x });
            }, i * 1000 / 60);
        };
        for (var i = 1; i <= frames; i++) {
            _loop_2(i);
        }
    },
    scrollEvent: function () {
        var currentScrollX = this.scrollBox.scrollLeft;
        for (var i in this.ambassadorsAreaLimits) {
            if (this.ambassadorsAreaLimits[i] > currentScrollX) {
                this.selectIndex(parseInt(i));
                return;
            }
        }
        this.selectIndex(this.ambassadorsAreaLimits.length);
    },
    selectIndex: function (index) {
        var selectedIndex = Math.max(0, index - 1);
        var selectedButton = this.buttons[selectedIndex];
        this.buttons.forEach(function (button) {
            if (button == selectedButton) {
                button.classList.add('focus');
            }
            else {
                button.classList.remove('focus');
            }
        });
    }
};
ambassadors.setup();
var ressources = {
    index: 0,
    touch: {
        startX: null,
        currentX: null
    },
    elements: document.querySelectorAll('#words .ressource').length,
    container: document.querySelector('#js-ressourcesContainer'),
    ressourceLeftNav: document.querySelector('#js-ressourceLeftNav'),
    ressourceRightNav: document.querySelector('#js-ressourceRightNav'),
    goTo: function (_index) {
        var cardsPerScreen = size.width / (this.container.offsetWidth / this.elements);
        var maxIndexOffset = Math.max(0, Math.round(cardsPerScreen - 1.6));
        this.index = _index >= 0 ? _index % (this.elements - maxIndexOffset) : this.elements - 1 - maxIndexOffset;
        this.container.style.transform = "translateX(-" + 100 * this.index / this.elements + "%)";
    },
    computeOffset: function () {
        return (this.touch.startX - this.touch.currentX) / size.width * 2;
    },
    touchStart: function (e) {
        this.touch.startX = e.touches.item(0).pageX;
        this.touch.currentX = e.touches.item(0).pageX;
        this.container.classList.add('dragging');
    },
    touchMove: function (e) {
        if (this.touch.startX == null) {
            return;
        }
        this.touch.currentX = e.touches.item(0).pageX;
        var offset = this.computeOffset() * 100 / this.elements;
        this.container.style.transform = "translateX(-" + ((100 * this.index / this.elements) + offset) + "%)";
    },
    touchEnd: function () {
        if (this.touch.startX == null) {
            return;
        }
        var offset = Math.round(this.computeOffset());
        this.container.classList.remove('dragging');
        ressources.goTo(ressources.index + offset);
        this.touch.startX = null;
        this.touch.currentX = null;
    }
};
ressources.container.addEventListener('touchstart', function (_e) { return ressources.touchStart(_e); });
ressources.container.addEventListener('touchmove', function (_e) { return ressources.touchMove(_e); });
ressources.container.addEventListener('touchend', function (_e) { return ressources.touchEnd(); });
ressources.ressourceRightNav.addEventListener('click', function (_) {
    ressources.goTo(ressources.index + 1);
});
ressources.ressourceLeftNav.addEventListener('click', function (_) {
    ressources.goTo(ressources.index - 1);
});
